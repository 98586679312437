import(/* webpackMode: "eager" */ "/home/channel21/webs/www.channel21.de/releases/20241014220058/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/channel21/webs/www.channel21.de/releases/20241014220058/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/channel21/webs/www.channel21.de/releases/20241014220058/src/components/Authentication/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CartSidebar"] */ "/home/channel21/webs/www.channel21.de/releases/20241014220058/src/components/Cart/Sidebar/CartSidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FooterItemCollapse"] */ "/home/channel21/webs/www.channel21.de/releases/20241014220058/src/components/Footer/components/FooterItemCollapse.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/channel21/webs/www.channel21.de/releases/20241014220058/src/components/HeaderNotice.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CategoryBadges","CategoryBadgesSkeleton"] */ "/home/channel21/webs/www.channel21.de/releases/20241014220058/src/components/Menu/CategoryBadges.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/channel21/webs/www.channel21.de/releases/20241014220058/src/components/Menu/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Sidebar"] */ "/home/channel21/webs/www.channel21.de/releases/20241014220058/src/components/Menu/Sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FlyoutPortal"] */ "/home/channel21/webs/www.channel21.de/releases/20241014220058/src/components/Modal/Flyout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ScrollContainer","ScrollItem"] */ "/home/channel21/webs/www.channel21.de/releases/20241014220058/src/components/Scroll/Container.tsx");
